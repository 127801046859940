import { useEffect } from 'react';
import html2canvas from 'html2canvas';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const LOCAL_STORAGE_KEY = 'climbcrossUserData';

// The empty dependency array is intentional and vital
function useMountEffect(func) {
    // eslint-disable-next-line
    useEffect(func, []);
}

function getNumPuzzles() {
    let url = `${API_BASE_URL}/puzzles`;
    let xhr = new XMLHttpRequest();
    let result;
    xhr.onload = e => {
        const resp = JSON.parse(e.target.responseText);
        if (e.target.status === 200) {
            result = resp.count;
        } else {
            console.error('Error:', resp.error);
            result = null;
        }
    };
    xhr.open('GET', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    try {
        xhr.send();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return;
    }
}

function getAllPuzzles() {
    let url = `${API_BASE_URL}/puzzles/all`;
    let xhr = new XMLHttpRequest();
    let result;
    xhr.onload = e => {
        const resp = JSON.parse(e.target.responseText);
        if (e.target.status === 200) {
            result = resp;
        } else {
            console.error('Error:', resp.error);
            result = null;
        }
    };
    xhr.open('GET', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    try {
        xhr.send();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return;
    }
}

function downloadDivAsImage(divId, fileName) {
    const element = document.getElementById(divId);

    html2canvas(element).then(canvas => {
        // Create a temporary anchor element
        const link = document.createElement('a');

        // Convert canvas to blob
        canvas.toBlob(blob => {
            const url = URL.createObjectURL(blob);

            // Set link properties
            link.href = url;
            link.download = fileName || 'div-image.png';

            // Append to body, click, and remove
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Revoke the blob URL
            URL.revokeObjectURL(url);
        });
    });
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

function fetchPuzzle(index, sorted) {
    let url = `${API_BASE_URL}/puzzles?index=${index}`;
    let xhr = new XMLHttpRequest();
    let result;
    xhr.onload = e => {
        const resp = JSON.parse(e.target.responseText);
        if (e.target.status === 200) {
            if (!sorted) {
                const middleRows = shuffleArray(resp.slice(1, -1));
                result = [resp[0], ...middleRows, resp[resp.length - 1]];
            } else {
                result = resp;
            }
        } else {
            console.error('Error:', resp.error)
            result = null;
        }
    };
    xhr.open('GET', url, false);
    xhr.setRequestHeader('Content-Type', 'application/json');
    try {
        xhr.send();
        return result;
    } catch (error) {
        console.error('Error:', error);
        return;
    }
}

function fetchNextPuzzle(currIndex, userData) {
    const numPuzzles = getNumPuzzles();
    let nextIndex = (currIndex + 1) % numPuzzles;

    for (let i = 0; i < numPuzzles; i++) {
        if (!userData[nextIndex].solved) {
            return [fetchPuzzle(nextIndex), nextIndex];
        }
        nextIndex = (nextIndex + 1) % numPuzzles;
    }

    return [fetchPuzzle(nextIndex), nextIndex];
}

const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export {
    API_BASE_URL,
    LOCAL_STORAGE_KEY,
    useMountEffect,
    getNumPuzzles,
    fetchPuzzle,
    fetchNextPuzzle,
    formatTime,
    getAllPuzzles,
    downloadDivAsImage
};