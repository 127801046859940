import React from "react";

import { Social } from "./Social";
import { ImTerminal } from "react-icons/im";
import { SiGmail } from "react-icons/si";
import { RxLinkedinLogo } from "react-icons/rx";

function TakedownNotice() {
    return (
        <div className="takedown modal">
            <h3>Error 404:</h3>
            <h3>Game not Found</h3>
            <p>This project has unfortunately been taken down, but thank you to everyone who supported the site!  Before you go, feel free to explore my other work at the links below!</p>
            <div id="socials" className="takedown">
                <Social label="portfolio" href="https://tdepodesta.github.io">
                    <ImTerminal />
                </Social>
                <Social label="gmail" href="mailto:tdepodesta@college.harvard.edu?subject=Climbcross Rocks!">
                    <SiGmail />
                </Social>
                <Social label="linkedin" href="https://www.linkedin.com/in/trevor-depodesta-4225b1290/">
                    <RxLinkedinLogo />
                </Social>
            </div>
        </div>
    )
}

export { TakedownNotice }